import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { UIProvider } from './context/UI';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <UIProvider>
          <ToastContainer />
          <App />
        </UIProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

