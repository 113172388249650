import { createSlice } from "@reduxjs/toolkit";

const configSlice = createSlice({
    name: 'configSlice',
    initialState: {
        loggedIn: null,
        socketConnected: false,
        stateId: '',
        storeName: '',
        storeId: '',
    },
    reducers: {
        updateConnect(state, action){
            state.socketConnected = action.payload;
        },  
        updateLogin(state, action){
            state.loggedIn = action.payload
        },
        updateState(state, action){
            state.stateId = action.payload
        },
        updateStoreName(state, action){
            state.storeName = action.payload
        },
        updateStoreId(state, action){
            state.storeId = action.payload
        }
    }
})

export const {updateConnect, updateLogin, updateState, updateStoreName, updateStoreId} = configSlice.actions;
export default configSlice.reducer;