import { Avatar, Box, Button, Chip, FormControl, InputAdornment, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { stringAvatar, useApiRequest } from '../store/Common';
import { LoadingButton } from '@mui/lab';
import { PersonAdd } from '@mui/icons-material';
import { useCommonUI } from '../context/UI';
import moment from 'moment/moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { ClipLoader } from 'react-spinners';

function CustomersPage() {

    const {openSnackbar} = useCommonUI();
    const { postData} = useApiRequest();
    const [page, setPage] = useState(2);
    const [totalDocs, setTotalDocs] = useState(0);
    const [customersList, setCustomersList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const containerRef = useRef();
    const [bodyData, setBodyData] = useState({type: [], name: '', start: 0, end: 0, dtype: "created"});

    const [newCustomerModal, setNewCustomerModal] = useState(false);
    const [newCustomer, setNewCustomer] = useState({ name: '', phone: '', company: '', address: '', gst: '', type: 'Private Limited Company', incorporation: dayjs() });
    const [isSubmittingCustomer, setIsSubmittingCustomer] = useState(false);

    useEffect(()=>{
        const getOneData = async() =>{
            setIsLoading(true);
            setCustomersList([]);
            const resp = await postData('customers/lists/1', {type: [], name: '', start: 0, end: 0, dtype: "created"});
            if(resp){
                setCustomersList(resp.data.docs);
                setPage(resp.data.nextPage);
                setTotalDocs(resp.data.totalDocs);
            }
            setIsLoading(false);
        }
        getOneData();
    },[postData]);

    const debounce = (func, delay) => {
        let timer;
        return function() {
            const context = this;
            const args = arguments;
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(context, args);
            }, delay);
        };
    };
    const getData = useCallback(async(page, body) =>{
        setIsLoading(true);
        const resp = await postData('customers/lists/'+page, body);
        if(resp){
            setCustomersList((prevArray) => [...prevArray, ...resp.data.docs]);
            setPage(resp.data.nextPage);
            setTotalDocs(resp.data.totalDocs);
        }
        setIsLoading(false);
    },[postData]);

    const handleScroll = useCallback(() => {
        const currentContainer = containerRef.current;
        if (currentContainer && customersList.length > 0 && !isLoading) {
            const scrollOffset = currentContainer.scrollHeight - (currentContainer.scrollTop + currentContainer.clientHeight);
            if(currentContainer.scrollTop > 0 && scrollOffset >= 0 && scrollOffset < 10 && page){
                getData(page, bodyData);
            }
        }
    },[getData, isLoading, customersList.length, page, bodyData])
    
    //on scroll trigger
    useEffect(() => {
        const currentContainer = containerRef.current;
        const debouncedScrollHandler = debounce(handleScroll, 500);
        currentContainer.addEventListener('scroll', debouncedScrollHandler);
        return () => {
            currentContainer.removeEventListener('scroll', debouncedScrollHandler);
        };
    }, [handleScroll]);

    const handleNewCustomer = (e) => {
        const { name, value } = e.target;
        setNewCustomer({ ...newCustomer, [name]: value });
    };
    const openNewCustomerModal = () =>{
        setNewCustomer({ name: '', phone: '', company: '', address: '', gst: '', type: 'Private Limited Company', incorporation: dayjs() });
        setNewCustomerModal(true);
    }
    const closeNewCustomerModal = () =>{
        if(isSubmittingCustomer){
            return;
        }
        setNewCustomerModal(false);
    }

    const submitNewCustomer = async(e) =>{
        e.preventDefault();
        setIsSubmittingCustomer(true);
        const resp = await postData('customers/new', newCustomer);
        if(resp){
            setCustomersList((prevArray) => [resp.data, ...prevArray]);
            setNewCustomerModal(false);
            openSnackbar("Customer Added", "success");
        }
        setIsSubmittingCustomer(false);
    }

    return (
        <Stack padding={2} sx={{background: '#d8d9dd', height: '100%'}}>
            <Stack padding={2} pt={4} borderRadius={5} sx={{background: 'linear-gradient(to bottom, #e2e3e5, #cfd6e6)', flex: 1}}>
                <Stack px={2} flexDirection={'row'} justifyContent={'space-between'}>
                    <Typography variant='h4'>Customers ({totalDocs})</Typography>
                    <Button variant='contained' onClick={openNewCustomerModal}>New Customer</Button>
                </Stack>
                <Stack mt={2.5} flexDirection='row'alignItems='center' width='100%' paddingX={4}>

                </Stack>
                {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <ClipLoader size={15} color='#000' />
                    <Typography variant='body'>Loading Customers</Typography>
                </Stack>}
                <Stack mt={1} p={2} pt={0} height='calc(100vh - 338px)' overflow='auto' gap={3} ref={containerRef}>

                    {customersList.map(customer=>{
                        return <Stack key={customer.id} flexDirection='row' gap={1} p={1.2} alignItems='center' sx={{background:'#fff', border: '1px solid rgb(226, 232, 240)', borderRadius: '9px', boxShadow: '5px 3px 5px #00000005', color: 'rgb(71, 85, 105)'}}>
                            <Box width={'5%'}>
                                <Avatar {...stringAvatar(customer.name)} />
                            </Box>
                            <Stack width={'15%'}>
                                <Typography variant='h5' color='#000'>{customer.name}</Typography>
                                <Typography variant='h6'>+91 {customer.phone}</Typography>
                            </Stack>
                            <Stack width={'30%'}>
                                <Typography variant='h6' color='#000'>{customer.companyName}</Typography>
                                <Typography variant='body'>{customer.type}</Typography>
                            </Stack>
                            <Box width={'20%'}>
                                {<Typography color='#000'>Incorporation: {moment(customer.incorporation).format('Do MMM yyyy')}</Typography>}
                                {<Typography>Onboarding: {moment(customer.onboarding).format('Do MMM yyyy')}</Typography>}
                            </Box>
                            <Box width={'30%'}>

                            </Box>
                            <Box width={'5%'}>
                                <Button size='small' variant='contained'>Details</Button>
                            </Box>
                        </Stack>
                    })}

                </Stack>
            </Stack>

            <Modal open={newCustomerModal} onClose={closeNewCustomerModal}>
                <Box component="form" sx={{position: 'absolute',top: '20%', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2} onSubmit={submitNewCustomer}>
                    <Stack gap={2}>
                        <Typography variant='h6'>Add New Customer</Typography>
                        <TextField label="Name" name='name' value={newCustomer.name} onChange={handleNewCustomer} variant="outlined" fullWidth required/>
                        <TextField label="Phone" name='phone' type='number' value={newCustomer.phone} onChange={handleNewCustomer} variant="outlined" fullWidth required
                        slotProps={{
                            input: {
                              startAdornment: (
                                <InputAdornment position="start">
                                    <Typography>+91</Typography>
                                </InputAdornment>
                              ),
                            },
                        }}/>
                        <TextField label="Company Name" name='company' value={newCustomer.company} onChange={handleNewCustomer} variant="outlined" fullWidth required/>

                        <TextField label="Address" name='address' value={newCustomer.address} onChange={handleNewCustomer} variant="outlined" fullWidth multiline rows={4} required/>

                        <TextField label="GST" name='gst' value={newCustomer.gst} onChange={handleNewCustomer} variant="outlined" fullWidth/>
                        
                        <FormControl fullWidth required>
                            <InputLabel>Type</InputLabel>
                            <Select name="type" value={newCustomer.type} onChange={handleNewCustomer} variant="outlined">
                                <MenuItem value={'Private Limited Company'}>Private Limited Company</MenuItem>
                            </Select>
                        </FormControl>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                            label="Incorporation Date"
                            value={newCustomer.incorporation}
                            onChange={(newValue) => handleNewCustomer({ target: { name: 'incorporation', value: newValue } })}
                            defaultValue={dayjs()}
                            />
                        </LocalizationProvider>

                        <LoadingButton fullWidth type="submit" variant="contained" color="primary" loading={isSubmittingCustomer}  loadingPosition='start' startIcon={<PersonAdd/>}><span>Add New Customer</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>

        </Stack>
    )
}

export default CustomersPage