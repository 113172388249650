import { Check, HighlightOff } from "@mui/icons-material";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar } from "@mui/material";
import { createContext, useCallback, useContext, useState } from "react";
import parse from 'html-react-parser';
const UIContext = createContext();

export const useCommonUI = () =>{
    return useContext(UIContext);
}

export const UIProvider = ({children}) => {

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [alertType, setAlertType] = useState('success');

    const [onConfirmAction, setOnConfirmAction] = useState(null);
    const [confirmActionParam, setConfirmActionParam] = useState(null); 
    const [dialogOpen, setDialogOpen] = useState(false); 
    const [dialogTitle, setdialogTitle] = useState(''); 
    const [dialogMessage, setdialogMessage] = useState(''); 

    const openSnackbar = useCallback((message, type) => {
        setSnackbarMessage(message);
        setAlertType(type);
        setSnackbarOpen(true);
    }, []);
    const closeSnackbar = () => {
        setSnackbarOpen(false);
        setSnackbarMessage('');
    };

    const openDialog = useCallback((title, message, onConfirm, param) => {
        setdialogTitle(title);
        setdialogMessage(message);
        setOnConfirmAction(() => onConfirm);
        setConfirmActionParam(param);
        setDialogOpen(true);
    }, []);
    const confirmDialog = () => {
        closeDialog();
        if (onConfirmAction) {
          onConfirmAction(confirmActionParam);
        }
    };
    const closeDialog = () => {
        setDialogOpen(false);
        setdialogTitle('');
        setdialogMessage('');
    };

    return (
        <UIContext.Provider value={{openSnackbar, openDialog}}>
            {children}

            {/* Snackbar component */}
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={closeSnackbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={closeSnackbar} severity={alertType} sx={{ width: '100%' }} variant='filled'>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            {/* Dialog component */}
            <Dialog open={dialogOpen} onClose={closeDialog}>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {parse(dialogMessage)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} variant="outlined" color="primary" autoFocus startIcon={<HighlightOff/>}>Cancel</Button>
                    <Button onClick={confirmDialog} variant="outlined" color="error" startIcon={<Check/>}>Confirm</Button>
                </DialogActions>
            </Dialog>

        </UIContext.Provider>
    )
}