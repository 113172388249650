import { Box, Button, FormControl, InputAdornment, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useCommonUI } from '../context/UI';
import { currency, useApiRequest } from '../store/Common';
import { PersonAdd } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { ClipLoader } from 'react-spinners';

function ProductsPage() {

    const {openSnackbar} = useCommonUI();
    const {fetchData, postData} = useApiRequest();
    const [productsList, setProductsList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const containerRef = useRef();

    const [newProductModal, setNewProductModal] = useState(false);
    const [newProduct, setNewProduct] = useState({ name: '', professional: 0, govt: 0, price: 0, status: true });
    const [isSubmittingProduct, setIsSubmittingProduct] = useState(false);

    useEffect(()=>{
        const getOneData = async() =>{
            setIsLoading(true);
            setProductsList([]);
            const resp = await fetchData('products/list');
            if(resp){
                setProductsList(resp.data);
            }
            setIsLoading(false);
        }
        getOneData();
    },[fetchData]);

    const handleNewProduct = (e) => {
        const { name, value } = e.target;
        setNewProduct({ ...newProduct, [name]: value });
    };
    const openNewProductModal = () =>{
        setNewProduct({ name: '', professional: 0, govt: 0, price: 0, status: true });
        setNewProductModal(true);
    }
    const closeNewProductModal = () =>{
        if(isSubmittingProduct){
            return;
        }
        setNewProductModal(false);
    }

    const submitNewProduct = async(e) =>{
        e.preventDefault();
        setIsSubmittingProduct(true);
        const resp = await postData('products/new', newProduct);
        if(resp){
            setProductsList((prevArray) => [resp.data, ...prevArray]);
            setNewProductModal(false);
            openSnackbar("Product Added", "success");
        }
        setIsSubmittingProduct(false);
    }

    return (
        <Stack padding={2} sx={{background: '#d8d9dd', height: '100%'}}>
            <Stack padding={2} pt={4} borderRadius={5} sx={{background: 'linear-gradient(to bottom, #e2e3e5, #cfd6e6)', flex: 1}}>

                <Stack px={2} flexDirection={'row'} justifyContent={'space-between'}>
                    <Typography variant='h4'>Products ({productsList.length})</Typography>
                    <Button variant='contained' onClick={openNewProductModal}>New Product</Button>
                </Stack>

                {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <ClipLoader size={15} color='#000' />
                    <Typography variant='body'>Loading Products</Typography>
                </Stack>}
                
                <Stack mt={5} flexDirection='row'alignItems='center' width='100%' paddingX={4} gap={1}>
                    <Box width='40%'>
                        <Typography><b>Name</b></Typography>
                    </Box>
                    <Box width='15%'>
                        <Typography><b>Professional Fees</b></Typography>
                    </Box>
                    <Box width='15%'>
                        <Typography><b>Govt. Fees</b></Typography>
                    </Box>
                    <Box width='15%'>
                        <Typography><b>Total Price</b></Typography>
                    </Box>
                    <Box width='10%'>
                        <Typography><b>Details</b></Typography>
                    </Box>
                </Stack>
                <Stack mt={1} p={2} pt={0} height='calc(100vh - 338px)' overflow='auto' gap={3} ref={containerRef}>
                    {productsList.map(product=>{
                            return <Stack key={product.id} flexDirection='row' gap={1} p={1.2} alignItems='center' sx={{background:'#fff', border: '1px solid rgb(226, 232, 240)', borderRadius: '9px', boxShadow: '5px 3px 5px #00000005', color: 'rgb(71, 85, 105)'}}>
                            <Stack width={'40%'}>
                                <Typography variant='h6' color='#000'>{product.name}</Typography>
                            </Stack>
                            <Stack width={'15%'}>
                                <Typography>{currency} {product.professional}</Typography>
                            </Stack>
                            <Stack width={'15%'}>
                                <Typography>{currency} {product.govt}</Typography>
                            </Stack>
                            <Stack width={'15%'}>
                                <Typography>{currency} {product.price}</Typography>
                            </Stack>
                            <Box width={'10%'}>
                                <Button size='small' variant='contained'>Edit</Button>
                            </Box>
                        </Stack>
                    })}
                </Stack>

            </Stack>

            <Modal open={newProductModal} onClose={closeNewProductModal}>
                <Box component="form" sx={{position: 'absolute',top: '20%', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2} onSubmit={submitNewProduct}>
                    <Stack gap={2}>
                        <Typography variant='h6'>Add New Product</Typography>
                        <TextField label="Name" name='name' value={newProduct.name} onChange={handleNewProduct} variant="outlined" fullWidth required/>

                        <TextField label="Professional Fees" name='professional' type='number' value={newProduct.professional} onChange={handleNewProduct} variant="outlined" fullWidth required
                        slotProps={{
                            input: {
                              startAdornment: (
                                <InputAdornment position="start">
                                    <Typography>{currency}</Typography>
                                </InputAdornment>
                              ),
                            },
                        }}/>

                        <TextField label="Government Fees" name='govt' type='number' value={newProduct.govt} onChange={handleNewProduct} variant="outlined" fullWidth required
                        slotProps={{
                            input: {
                              startAdornment: (
                                <InputAdornment position="start">
                                    <Typography>{currency}</Typography>
                                </InputAdornment>
                              ),
                            },
                        }}/>

                        <TextField label="Total Price" name='price' type='number' value={parseFloat(newProduct.govt)+parseFloat(newProduct.professional)}  variant="outlined" fullWidth required disabled
                        slotProps={{
                            input: {
                              startAdornment: (
                                <InputAdornment position="start">
                                    <Typography>{currency}</Typography>
                                </InputAdornment>
                              ),
                            },
                        }}/>

                        <FormControl fullWidth required>
                            <InputLabel>Status</InputLabel>
                            <Select name="status" value={newProduct.status} onChange={handleNewProduct} variant="outlined">
                                <MenuItem value={true}>Active</MenuItem>
                                <MenuItem value={false}>Inactive</MenuItem>
                            </Select>
                        </FormControl>

                        <LoadingButton fullWidth type="submit" variant="contained" color="primary" loading={isSubmittingProduct}  loadingPosition='start' startIcon={<PersonAdd/>}><span>Add New Product</span></LoadingButton>

                    </Stack>
                </Box>
            </Modal>

        </Stack>
    )
}

export default ProductsPage