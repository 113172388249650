import axios from "axios";
import { useDispatch } from "react-redux";
import { useCommonUI } from "../context/UI";
import { useCallback } from "react";
import { updateLogin } from "./slices/configSlice";

export const currency = '₹';

export const apiUrl = 'https://demoapi.synxweb.co/';
const axiosInstance = axios.create({
    baseURL: apiUrl
});

const stringToColor = (string) =>{
    let hash = 0;
    let i;
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
}
export const stringAvatar = (input, textColor) => {
    if(!input || input === ""){
        input = "JD"
    }
    const words = input.split(' ');
    let out = words[0].charAt(0);
    if(words[1]){
        out += words[1].charAt(0);
    }
    
    return {
        sx: {
            bgcolor: stringToColor(out),
            color: '#fff'
        },
        children: out,
    };
}

axiosInstance.interceptors.request.use(config => {
    config.headers['x-session'] = localStorage.getItem('session');
    return config;
});

export const useApiRequest = () => {
    const {openSnackbar} = useCommonUI();
    const dispatch = useDispatch();

    const handleError = useCallback((error) => {
        if (error.response && error.response.status === 401) {
            dispatch(updateLogin(false));
            localStorage.removeItem('session');
            openSnackbar("Session Expired", 'error');
        } else {
            openSnackbar(error.message, 'error');
        }
    },[openSnackbar, dispatch])

    const fetchData = useCallback(async (endpoint) => {
        try {
            const response = await axiosInstance.get(endpoint);
            if(!response.data.status){
                throw new Error(response.data.message)
            }
            return response.data;
        } catch (error) {
            handleError(error);
            return null;
        }
    },[handleError])

    const postData = useCallback(async (endpoint, data) => {
        try {
            const response = await axiosInstance.post(endpoint, data);
            if(!response.data.status){
                throw new Error(response.data.message)
            }
            return response.data;
        } catch (error) {
            handleError(error);
            return null;
        }
    },[handleError])

    return { fetchData, postData };
}