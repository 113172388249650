import { Box, Button, InputAdornment, MenuItem, Paper, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { currency, useApiRequest } from '../store/Common';

function AddEstimate() {

    const {fetchData} = useApiRequest();
    const [productsList, setProductsList] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState('none');
    const [estimateItems, setEstimateItems] = useState([]);

    useEffect(()=>{
        const getProducts = async() =>{
            const resp = await fetchData('products/list');
            if(resp){
                setProductsList(resp.data);
            }
        }
        getProducts();
    },[fetchData]);

    const addEstimateItem = () =>{
        if(selectedProduct === "none"){
            return;
        }
        setEstimateItems((prevItems)=> [...prevItems, {...selectedProduct, gst: 0, amount: selectedProduct.price}]);
        setSelectedProduct('none');
    }

    const editEstimateItem = (index, fieldName, value) => { //todo check for NaN
        setEstimateItems((prevItems) =>
            prevItems.map((item, i) => {
                if (i === index) {
                    const updatedItem = { ...item, [fieldName]: value };
                    updatedItem.price = parseFloat(updatedItem.govt) + parseFloat(updatedItem.professional);
                    const gstValue = parseFloat((parseFloat(updatedItem.gst)/100)*parseFloat(updatedItem.price)).toFixed(2);
                    updatedItem.amount = parseFloat(updatedItem.price) + parseFloat(gstValue);
                    return updatedItem;
                }
                return item;
            })
        );
      };

    return (
        <Stack padding={2} sx={{background: '#d8d9dd', height: '100%'}}>
            <Stack padding={2} pt={4} borderRadius={5} sx={{background: 'linear-gradient(to bottom, #e2e3e5, #cfd6e6)', flex: 1}}>

                <Stack px={2} flexDirection={'row'} justifyContent={'space-between'}>
                    <Typography variant='h4'>Create Estimate</Typography>
                </Stack>

                <Stack>
                    <Paper elevation={3}>
                        {estimateItems.map((eItem, index)=>{
                            return <Stack key={index} p={2} sx={{borderBottom: '1px solid #aaa'}} flexDirection={'row'} gap={2}>
                                <Box width={'5%'}>
                                    {index + 1}
                                </Box>
                                <Stack width={'55%'} gap={2}>
                                    <Typography>{eItem.name}</Typography>
                                    <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'flex-end'} gap={2}>
                                        <Typography textAlign={'right'}>Professional Fees</Typography>
                                        <TextField type='number' size='small' sx={{width: 130}} value={eItem.professional} onChange={(e)=>{editEstimateItem(index, "professional", e.target.value)}} variant="outlined" required
                                        slotProps={{
                                            input: {
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Typography>{currency}</Typography>
                                                </InputAdornment>
                                            ),
                                            },
                                        }}/>
                                    </Stack>
                                    <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'flex-end'} gap={2}>
                                        <Typography textAlign={'right'}>Government Fees</Typography>
                                        <TextField type='number' size='small' sx={{width: 130}} value={eItem.govt}  onChange={(e)=>{editEstimateItem(index, "govt", e.target.value)}} variant="outlined" required
                                        slotProps={{
                                            input: {
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Typography>{currency}</Typography>
                                                </InputAdornment>
                                            ),
                                            },
                                        }}/>
                                    </Stack>
                                </Stack>
                                <Stack width={'15%'}>
                                    <TextField type='number' size='small' sx={{width: 160}} value={eItem.price} variant="outlined" fullWidth required
                                    slotProps={{
                                        input: {
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Typography>{currency}</Typography>
                                            </InputAdornment>
                                        ),
                                        readOnly: true
                                        },
                                    }}/>
                                </Stack>
                                <Box width={'10%'}>
                                    <TextField type='number' size='small' sx={{width: 120}} value={eItem.gst} onChange={(e)=>{editEstimateItem(index, "gst", e.target.value)}} variant="outlined" fullWidth required
                                    slotProps={{
                                        input: {
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <Typography>%</Typography>
                                            </InputAdornment>
                                        )
                                        },
                                    }}/>
                                </Box>
                                <Stack width={'15%'}>
                                    <TextField type='number' size='small' sx={{width: 160}} value={eItem.amount} variant="outlined" fullWidth required
                                    slotProps={{
                                        input: {
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Typography>{currency}</Typography>
                                            </InputAdornment>
                                        ),
                                        readOnly: true
                                        },
                                    }}/>
                                </Stack>
                            </Stack>
                        })}

                        <Stack p={2} sx={{borderBottom: '1px solid #aaa'}} flexDirection={'row'} gap={2}>
                            <Box width={'5%'}>
                                1
                            </Box>
                            <Stack width={'55%'} gap={2}>
                                <Typography>Service Namesss</Typography>
                                <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'flex-end'} gap={2}>
                                    <Typography textAlign={'right'}>Professional Fees</Typography>
                                    <TextField type='number' size='small' sx={{width: 130}} value={'434'} variant="outlined" required
                                    slotProps={{
                                        input: {
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Typography>{currency}</Typography>
                                            </InputAdornment>
                                        ),
                                        },
                                    }}/>
                                </Stack>
                                <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'flex-end'} gap={2}>
                                    <Typography textAlign={'right'}>Government Fees</Typography>
                                    <TextField type='number' size='small' sx={{width: 130}} value={'434'} variant="outlined" required
                                    slotProps={{
                                        input: {
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Typography>{currency}</Typography>
                                            </InputAdornment>
                                        ),
                                        },
                                    }}/>
                                </Stack>
                            </Stack>
                            <Stack width={'15%'}>
                                <TextField type='number' size='small' sx={{width: 160}} value={'868'} variant="outlined" fullWidth required
                                slotProps={{
                                    input: {
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Typography>{currency}</Typography>
                                        </InputAdornment>
                                    ),
                                    readOnly: true
                                    },
                                }}/>
                            </Stack>
                            <Box width={'10%'}>
                                <TextField type='number' size='small' sx={{width: 120}} value={'18'} variant="outlined" fullWidth required
                                slotProps={{
                                    input: {
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <Typography>%</Typography>
                                        </InputAdornment>
                                    )
                                    },
                                }}/>
                            </Box>
                            <Stack width={'15%'}>
                                <TextField type='number' size='small' sx={{width: 160}} value={'1000'} variant="outlined" fullWidth required
                                slotProps={{
                                    input: {
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Typography>{currency}</Typography>
                                        </InputAdornment>
                                    ),
                                    readOnly: true
                                    },
                                }}/>
                            </Stack>
                        </Stack>

                        <Stack flexDirection={'row'} gap={2} alignItems={'center'} p={2}>
                            <Typography>Select Product</Typography>
                            <Select value={selectedProduct} onChange={(e)=>{setSelectedProduct(e.target.value)}} variant='outlined' sx={{flex: 1}}>
                                <MenuItem value={'none'}>None</MenuItem>
                                {productsList.map(product=>{
                                    return <MenuItem value={product}>{product.name} - {currency} {product.price}</MenuItem>
                                })}
                            </Select>
                            <Button variant='contained' onClick={addEstimateItem}>Add Product</Button>
                        </Stack>
                    </Paper>
                </Stack>

            </Stack>
        </Stack>
    )
}

export default AddEstimate