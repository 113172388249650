import { Avatar, Box, Button, Chip, FormControl, InputAdornment, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { stringAvatar, useApiRequest } from '../store/Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { LoadingButton } from '@mui/lab';
import { PersonAdd } from '@mui/icons-material';
import { useCommonUI } from '../context/UI';
import moment from 'moment/moment';
import { ClipLoader } from 'react-spinners';

function LeadsPage() {

    const {openSnackbar} = useCommonUI();
    const {fetchData, postData} = useApiRequest();
    const [page, setPage] = useState(2);
    const [totalDocs, setTotalDocs] = useState(0);
    const [leadsList, setLeadsList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const containerRef = useRef();
    const [bodyData, setBodyData] = useState({priority: [], type: [], name: '', start: 0, end: 0, dtype: "created"});

    const [servicesList, setServicesList] = useState([]);
    const [agentsList, setAgentsList] = useState([]);

    const [newLeadModal, setNewLeadModal] = useState(false);
    const [newLead, setNewLead] = useState({ name: '', phone: '', service: 'none', source: 'others', type: 'hot', priority: 'high', agent: 'none', company: '' });
    const [isSubmittingLead, setIsSubmittingLead] = useState(false);

    useEffect(()=>{
        const getOneData = async() =>{
            setIsLoading(true);
            setLeadsList([]);
            const resp = await postData('leads/lists/1', {priority: [], type: [], name: '', start: 0, end: 0, dtype: "created"});
            if(resp){
                setLeadsList(resp.data.docs);
                setPage(resp.data.nextPage);
                setTotalDocs(resp.data.totalDocs);
            }
            setIsLoading(false);
        }
        getOneData();
    },[postData]);

    const debounce = (func, delay) => {
        let timer;
        return function() {
            const context = this;
            const args = arguments;
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(context, args);
            }, delay);
        };
    };
    const getData = useCallback(async(page, body) =>{
        setIsLoading(true);
        const resp = await postData('leads/lists/'+page, body);
        if(resp){
            setLeadsList((prevArray) => [...prevArray, ...resp.data.docs]);
            setPage(resp.data.nextPage);
            setTotalDocs(resp.data.totalDocs);
        }
        setIsLoading(false);
    },[postData]);

    const handleScroll = useCallback(() => {
        const currentContainer = containerRef.current;
        if (currentContainer && leadsList.length > 0 && !isLoading) {
            const scrollOffset = currentContainer.scrollHeight - (currentContainer.scrollTop + currentContainer.clientHeight);
            if(currentContainer.scrollTop > 0 && scrollOffset >= 0 && scrollOffset < 10 && page){
                getData(page, bodyData);
            }
        }
    },[getData, isLoading, leadsList.length, page, bodyData])
    
    //on scroll trigger
    useEffect(() => {
        const currentContainer = containerRef.current;
        const debouncedScrollHandler = debounce(handleScroll, 500);
        currentContainer.addEventListener('scroll', debouncedScrollHandler);
        return () => {
            currentContainer.removeEventListener('scroll', debouncedScrollHandler);
        };
    }, [handleScroll]);

    useEffect(()=>{
        const getLists = async() =>{
            const resp = await fetchData('services/list');
            const resp1 = await fetchData('users/agents');
            if(resp && resp1){
                setAgentsList(resp1.data);
                setServicesList(resp.data);
            }
        }
        getLists();
    },[fetchData])

    const handleNewLead = (e) => {
        const { name, value } = e.target;
        setNewLead({ ...newLead, [name]: value });
    };
    const openNewLeadModal = () =>{
        setNewLead({ name: '', phone: '', service: 'none', source: 'others', type: 'hot', priority: 'high', agent: 'none', company: '' });
        setNewLeadModal(true);
    }
    const closeNewLeadModal = () =>{
        if(isSubmittingLead){
            return;
        }
        setNewLeadModal(false);
    }

    const submitNewLead = async(e) =>{
        e.preventDefault();
        setIsSubmittingLead(true);
        const resp = await postData('leads/new', newLead);
        if(resp){
            setLeadsList((prevArray) => [resp.data, ...prevArray]);
            setNewLeadModal(false);
            openSnackbar("Lead Added", "success");
        }
        setIsSubmittingLead(false);
    }

    return (
        <Stack padding={2} sx={{background: '#d8d9dd', height: '100%'}}>
            <Stack padding={2} pt={4} borderRadius={5} sx={{background: 'linear-gradient(to bottom, #e2e3e5, #cfd6e6)', flex: 1}}>
                <Stack px={2} flexDirection={'row'} justifyContent={'space-between'}>
                    <Typography variant='h4'>Leads ({totalDocs})</Typography>
                    <Button variant='contained' onClick={openNewLeadModal}>New Lead</Button>
                </Stack>
                <Stack mt={2.5} flexDirection='row'alignItems='center' width='100%' paddingX={4}>

                </Stack>
                {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <ClipLoader size={15} color='#000' />
                    <Typography variant='body'>Loading Leads</Typography>
                </Stack>}
                <Stack mt={1} p={2} pt={0} height='calc(100vh - 338px)' overflow='auto' gap={3} ref={containerRef}>

                    {leadsList.map(lead=>{
                        return <Stack key={lead.id} flexDirection='row' gap={1} p={1.2} alignItems='center' sx={{background:'#fff', border: '1px solid rgb(226, 232, 240)', borderRadius: '9px', boxShadow: '5px 3px 5px #00000005', color: 'rgb(71, 85, 105)'}}>
                            <Box width={'5%'}>
                                <Avatar {...stringAvatar(lead.name)} />
                            </Box>
                            <Stack width={'15%'}>
                                <Typography variant='h5' color='#000'>{lead.name}</Typography>
                                <Typography variant='h6'>+91 {lead.phone}</Typography>
                            </Stack>
                            <Stack width={'15%'}>
                                <Typography>{lead.companyName}</Typography>
                                <Box>
                                    {lead.converted && <Chip sx={{color: '#000'}} label={'Customer'} color="primary" />}
                                </Box>
                            </Stack>
                            <Stack width={'20%'}>
                                {lead.source === "instagram" && <Typography><FontAwesomeIcon icon={faInstagram} /> Instagram</Typography>}
                                {lead.source === "facebook" && <Typography><FontAwesomeIcon icon={faFacebook} /> Facebook</Typography>}
                                {lead.source === "whatsapp" && <Typography><FontAwesomeIcon icon={faWhatsapp} /> Whatsapp</Typography>}
                                {lead.source === "others" && <Typography>Others</Typography>}
                                <Typography>Created: {moment(lead.created).format('Do MMM yyyy hh:mm a')}</Typography>
                            </Stack>
                            <Stack width={'10%'}>
                                {lead.assigned && <Typography>{lead.assigned.name}</Typography>}
                            </Stack>
                            <Box width={'15%'}>
                                {lead.nextFollowup && <Typography color='#000'>Next: {moment(lead.nextFollowup).format('Do MMM yyyy hh:mm a')}</Typography>}
                                {lead.lastFollowup && <Typography>Last: {moment(lead.lastFollowup).format('Do MMM yyyy hh:mm a')}</Typography>}
                            </Box>
                            <Box width={'5%'}>
                                {lead.type === "hot" && <Chip sx={{margin: '5px'}} label={'HOT'} color="error" />}
                                {lead.type === "cold" && <Chip sx={{margin: '5px'}} label={'COLD'} color="success" />}
                            </Box>
                            <Box width={'8%'}>
                                {lead.priority && lead.priority === "high" && <Chip sx={{margin: '5px'}} label={'HIGH'} color="error" />}
                                {lead.priority && lead.priority === "medium" && <Chip sx={{margin: '5px'}} label={'MEDIUM'} color="warning" />}
                                {lead.priority && lead.priority === "low" && <Chip sx={{margin: '5px'}} label={'LOW'} color="success" />}
                            </Box>
                            <Box width={'7%'}>
                                <Button size='small' variant='contained'>Details</Button>
                            </Box>
                        </Stack>
                    })}

                    <Stack flexDirection='row' gap={1} p={1.2} alignItems='center' sx={{background:'#fff', border: '1px solid rgb(226, 232, 240)', borderRadius: '9px', boxShadow: '5px 3px 5px #00000005', color: 'rgb(71, 85, 105)'}}>
                        <Box width={'5%'}>
                            <Avatar {...stringAvatar('Kent Dodds')} />
                        </Box>
                        <Stack width={'15%'}>
                            <Typography variant='h5' color='#000'>Person Name</Typography>
                            <Typography variant='h6'>+91 8982989898</Typography>
                        </Stack>
                        <Stack width={'15%'}>
                            <Typography>Privayte Limited COmpany</Typography>
                            <Box>
                                <Chip sx={{color: '#000'}} label={'Customer'} color="primary" />
                            </Box>
                        </Stack>
                        <Stack width={'20%'}>
                            <Typography><FontAwesomeIcon icon={faInstagram} /> Instagram</Typography>
                            <Typography>Created: 22-23-2024 5:45 am</Typography>
                        </Stack>
                        <Stack width={'10%'}>
                            <Typography>Agent Onetwo</Typography>
                        </Stack>
                        <Box width={'15%'}>
                            <Typography color='#000'>Next: 25-12-2024</Typography>
                            <Typography>Last: 25-11-2024</Typography>
                        </Box>
                        <Box width={'5%'}>
                            <Chip sx={{margin: '5px'}} label={'HOT'} color="error" />
                        </Box>
                        <Box width={'5%'}>
                            <Chip sx={{margin: '5px'}} label={'HIGH'} color="error" />
                        </Box>
                        <Box width={'10%'}>
                            <Button size='small' variant='contained'>Details</Button>
                        </Box>
                    </Stack>
                </Stack>
            </Stack>

            <Modal open={newLeadModal} onClose={closeNewLeadModal}>
                <Box component="form" sx={{position: 'absolute',top: '20%', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2} onSubmit={submitNewLead}>
                    <Stack gap={2}>
                        <Typography variant='h6'>Add New Lead</Typography>
                        <TextField label="Name" name='name' value={newLead.name} onChange={handleNewLead} variant="outlined" fullWidth required/>
                        <TextField label="Phone" name='phone' type='number' value={newLead.phone} onChange={handleNewLead} variant="outlined" fullWidth required
                        slotProps={{
                            input: {
                              startAdornment: (
                                <InputAdornment position="start">
                                    <Typography>+91</Typography>
                                </InputAdornment>
                              ),
                            },
                        }}/>
                        <TextField label="Company Name" name='company' value={newLead.company} onChange={handleNewLead} variant="outlined" fullWidth/>
                        <FormControl fullWidth required>
                            <InputLabel>Service</InputLabel>
                            <Select name="service" value={newLead.service} onChange={handleNewLead} variant="outlined">
                                <MenuItem value={'none'}>None</MenuItem>
                                {servicesList.map((srv) => {
                                    return <MenuItem key={srv.id} value={srv.id}>{srv.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth required>
                            <InputLabel>Source</InputLabel>
                            <Select name="source" value={newLead.source} onChange={handleNewLead} variant="outlined">
                                <MenuItem value={'instagram'}><FontAwesomeIcon icon={faInstagram} />&nbsp;&nbsp;Instagram</MenuItem>
                                <MenuItem value={'facebook'}><FontAwesomeIcon icon={faFacebook} />&nbsp;&nbsp;Facebook</MenuItem>
                                <MenuItem value={'whatsapp'}><FontAwesomeIcon icon={faWhatsapp} />&nbsp;&nbsp;Whatsapp</MenuItem>
                                <MenuItem value={'others'}>Others</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth required>
                            <InputLabel>Type</InputLabel>
                            <Select name="type" value={newLead.type} onChange={handleNewLead} variant="outlined">
                                <MenuItem value={'hot'}>Hot</MenuItem>
                                <MenuItem value={'cold'}>Cold</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth required>
                            <InputLabel>Priority</InputLabel>
                            <Select name="priority" value={newLead.priority} onChange={handleNewLead} variant="outlined">
                                <MenuItem value={'high'}>High</MenuItem>
                                <MenuItem value={'medium'}>Medium</MenuItem>
                                <MenuItem value={'low'}>Low</MenuItem>
                                <MenuItem value={'none'}>None</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth required>
                            <InputLabel>Agent</InputLabel>
                            <Select name="agent" value={newLead.agent} onChange={handleNewLead} variant="outlined">
                                <MenuItem value={'none'}>None</MenuItem>
                                {agentsList.map((agent) => {
                                    return <MenuItem key={agent.id} value={agent.id}>{agent.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <LoadingButton fullWidth type="submit" variant="contained" color="primary" loading={isSubmittingLead}  loadingPosition='start' startIcon={<PersonAdd/>}><span>Add New Lead</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>

        </Stack>
    )
}

export default LeadsPage