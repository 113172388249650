import logo from './logo.svg';
import './App.css';
import LeadsPage from './pages/LeadsPage';
import { createTheme, CssBaseline, Stack, ThemeProvider } from '@mui/material';
import CustomersPage from './pages/CustomersPage';
import ProductsPage from './pages/ProductsPage';
import { Link, Route, Routes } from 'react-router-dom';
import AddEstimate from './pages/AddEstimate';

function App() {

  const lightTheme = createTheme({
    palette: {
      mode: 'light',
      primary: {main: '#fbb755'}
    }
  });

  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline/>
      <Stack flexDirection={'row'} height={'100vh'}>
        <Stack width={250}>
          <Link to={'/leads'}>Leads</Link>
          <Link to={'/customers'}>customers</Link>
          <Link to={'/products'}>Products</Link>
          <Link to={'/addestimate'}>Add Estimate</Link>
        </Stack>
        <Stack flexGrow={1} height={'100%'}>
          <Routes>
            <Route path='/leads' element={<LeadsPage/>} />
            <Route path='/customers' element={<CustomersPage/>} />
            <Route path='/products' element={<ProductsPage/>} />
            <Route path='/addestimate' element={<AddEstimate/>} />
          </Routes>
        </Stack>
      </Stack>
    </ThemeProvider>
  );
}

export default App;
